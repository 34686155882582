import React, { Fragment } from "react";

const About = () => {
  return (
    <Fragment>
      <div className="text-right">
        <h1>نبذة عن هذه الخدمة </h1>
        <p>
          {" "}
          .. هذه الصفحة تختص بالتعريف عن أعضاء هيئة التدريس بجامعة الزاوية من حيث
          الدرجة و المؤهل العلمي و سنة الالتحاق و الاعمال التي شغلها بالجامعة الخ{" "}
        </p>
      </div>
    </Fragment>
  );
};

export default About;

import React, { Fragment } from "react";
import Search from "../subject/SubjectFilter";
import Subjects from "../subject/Subjects";

const Subject = () => (
  <Fragment>
    <Search />
    <Subjects />
  </Fragment>
);

export default Subject;

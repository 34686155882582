import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const UserItem = ({ user: { _id, name, image } }) => {
  return (
    <div className="card text-center">
      <img
        src={`https://portal.zu.edu.ly${image}`}
        alt=""
        className="round-img"
        style={{ width: "60px" }}
      />
      <h6>{name}</h6>

      <div>
        <Link to={`/${_id}`} className="btn btn-dark btn-sm mt-2">
          المزيد ...
        </Link>
      </div>
    </div>
  );
};

UserItem.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserItem;

import React, { useReducer } from "react";
import axios from "axios";
import SubjectContext from "./subjectContext";
import SubjectsReducer from "./SubjectsReducer";
import {
  SET_LOADING,
  CLEAR_SUBJECT,
  GET_SUBJECT,
  GET_SUBJECTS,
  FILTER_STUDENT,
  CLEAR_FILTER
} from "../types";

const SubjrectState = props => {
  const initialState = {
    subjects: [],
    subject: {},
    loading: false
  };

  const [state, dispatch] = useReducer(SubjectsReducer, initialState);

  // Get all Users
  const getSubjects = async () => {
    setLoading();
    try {
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
      };
      const res = await axios.get(
        `https://portal.zu.edu.ly/api/subjects/all`,
        config
      );

      dispatch({
        type: GET_SUBJECTS,
        payload: res.data
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Get User
  const getSubject = async id => {
    setLoading();

    const res = await axios.get(
      `https://portal.zu.edu.ly/api/facultymembers/subject/${id}`
    );

    dispatch({
      type: GET_SUBJECT,
      payload: res.data
    });
  };

  // filter student
  const filterSubject = text => dispatch => {
    dispatch({ type: FILTER_STUDENT, payload: text });
  };

  //clear filter
  const clearFilter = () => dispatch => {
    dispatch({ type: CLEAR_FILTER });
  };

  // Clear Users
  const clearUsers = () => dispatch({ type: CLEAR_SUBJECT });

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <SubjectContext.Provider
      value={{
        subjects: state.subjects,
        subject: state.subject,
        loading: state.loading,
        getSubjects,
        clearUsers,
        getSubject,
        filterSubject,
        clearFilter
      }}
    >
      {props.children}
    </SubjectContext.Provider>
  );
};

export default SubjrectState;

export const SEARCH_USERS = "SEARCH_USERS";
export const GET_USER = "GET_USER";
export const CLEAR_USERS = "CLEAR_USERS";
export const GET_SUBJECT = "GET_SUBJECT";
export const SET_LOADING = "SET_LOADING";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const GET_USERS = "GET_USERS";
export const CLEAR_SUBJECT = "CLEAR_SUBJECT";
export const SEARCH_SUBJECT = "SEARCH_SUBJECT";
export const GET_SUBJECTS = "GET_SUBJECTS";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const FILTER_STUDENT = "FILTER_STUDENT";

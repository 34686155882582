import {
  SEARCH_SUBJECT,
  SET_LOADING,
  CLEAR_SUBJECT,
  GET_SUBJECT,
  GET_SUBJECTS,
  FILTER_STUDENT,
  CLEAR_FILTER
} from "../types";

export default (state, action) => {
  switch (action.type) {
    case SEARCH_SUBJECT:
      return {
        ...state,
        subjects: action.payload,
        loading: false
      };
    case GET_SUBJECTS:
      return {
        ...state,
        subjects: action.payload,
        loading: false
      };

    case FILTER_STUDENT:
      return {
        ...state,
        filtered: state.subjects.filter(subject => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            subject.subjectname.match(regex) ||
            subject.subjectteacher.match(regex) ||
            subject.department.match(regex)
          );
        })
      };
    case CLEAR_FILTER:
      return {
        ...state,
        filtered: null
      };

    case CLEAR_SUBJECT:
      return {
        ...state,
        subjects: [],
        loading: false
      };
    case GET_SUBJECT: {
      return {
        ...state,
        subject: action.payload,
        loading: false
      };
    }
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

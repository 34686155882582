import React, { useRef, useContext, useEffect } from "react";
import SubjectContext from "../../context/Subjects/subjectContext";

const Search = () => {
  useEffect(() => {
    subjectContext.getSubjects();
  }, []);
  const subjectContext = useContext(SubjectContext);

  const text = useRef("");

  const onChange = e => {
    if (text.current.value !== "") {
      subjectContext.filterSubject(e.target.value);
    } else {
      subjectContext.clearFilter();
    }
  };

  return (
    <form>
      <p>hamedkbfkdsbckwbdkjsdbkcvbsdkbcv</p>
      <input
        ref={text}
        type="text"
        placeholder="بحث باسم الدورة..."
        onChange={onChange}
      />
    </form>
  );
};

export default Search;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import User from "./components/users/User";

import Alert from "./components/layout/Alert";
import Home from "./components/pages/Home";
import Subjects from "./components/pages/Subjects";
import About from "./components/pages/About";
import Subject from "./components/pages/HomeS";
import NotFound from "./components/pages/NotFound";

import GithubState from "./context/github/GithubState";
import SubjectState from "./context/Subjects/SubjectState";
import AlertState from "./context/alert/AlertState";
import "materialize-css/dist/css/materialize.min.css";

import M from "materialize-css/dist/js/materialize.min.js";

import "./App.css";

const App = () => {
  useEffect(() => {
    M.AutoInit();
  });
  return (
    <GithubState>
      <SubjectState>
        <AlertState>
          <Router>
            <div className="App">
              <Navbar />
              <div className="container">
                <Alert />
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/about" component={About} />
                  <Route exact path="/:id" component={User} />
                  <Route exact path="/subjects" component={Subjects} />
                  <Route exact path="/subject/:id" component={Subject} />
                  <Route component={NotFound} />
                </Switch>
              </div>
            </div>
          </Router>
        </AlertState>
      </SubjectState>
    </GithubState>
  );
};

export default App;

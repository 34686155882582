import React, { Fragment, useEffect, useContext } from "react";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom";
import GithubContext from "../../context/github/githubContext";
import "materialize-css/dist/css/materialize.min.css";

import M from "materialize-css/dist/js/materialize.min.js";

const User = ({ match }) => {
  const githubContext = useContext(GithubContext);

  const { getUser, loading, user } = githubContext;

  useEffect(() => {
    getUser(match.params.id);
    M.AutoInit();
    // getUserRepos(match.params.login);
    // eslint-disable-next-line
  }, []);

  console.log(user.research);
  const { name, faculty, image, department, sex, nationality } = user;

  const subjects = [];
  const researchs = [];

  for (var ks in user.subjects) {
    subjects.push(
      <div className="col s12 " style={{ direction: "rtl" }} key={ks}>
        <div className="card horizontal">
          <div className="card-image">
            <img
              src={`https://portal.zu.edu.ly${user.subjects[ks].image}`}
              alt="courses"
            />
          </div>
          <div className="card-stacked">
            <div className="card-content">
              <h5>{user.subjects[ks].subjectname}</h5>
              <p>{user.subjects[ks].about}</p>
            </div>
            <div className="card-action text-center">
              <Link to={`/subject/${user.subjects[ks]._id}`}>التفاصيل</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
  console.log(user.research);
  for (var id in user.research) {
    researchs.push(
      <div className="col s12 " style={{ direction: "rtl" }} key={id}>
        <div className="card ">
          <div className="card-content ">
            <span className="card-title">{user.research[id].filename}</span>
            <small>{user.research[id].writer}</small>
            <p>{user.research[id].discription}</p>
          </div>
          <div className="card-action">
            <div className="text-left">
              {user.research[id] && user.research[id].link ? (
                <a
                  href={user.research[id].link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  الانتقال لرابط
                  {"  "}
                  <i className="fas fa-angle-double-left"></i>
                </a>
              ) : (
                <a
                  href={`https://portal.zu.edu.ly${user.research[id].file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  تحميل الملف
                  {"  "}
                  <i className="fas fa-angle-double-down"></i>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (loading) return <Spinner />;

  return (
    <Fragment>
      <Link to="/" className="btn btn-light">
        الرجوع للبحث
      </Link>
      {/* Hireable:{' '}
			{hireable ? (
				<i className="fas fa-check text-success" />
			) : (
				<i className="fas fa-times-circle text-danger" />
			)} */}
      <div className="card text-right">
        <div className="grid-2 ">
          <div>
            {name && (
              <Fragment>
                <p>
                  الاستاذ <u> {name} </u> هو احد اعضاء هيئة التدريس بجامعة
                  الزاوية كلية <u> {faculty} </u>
                  قسم <u> {department} </u>
                  منذ سنة <u> {user.tss.datedegree} </u>
                </p>

                <p>
                  الجنسية :<u> {nationality}</u>
                </p>
                <p>
                  الجنس : <u>{sex}</u>
                </p>

                <p>
                  {" "}
                  المؤهل العلمي : <u>{user.tss && user.tss.qualification}</u>
                </p>
                <p>
                  التخصص العام : <u>{user.tss && user.tss.gspecialty}</u>
                </p>
                <p>
                  التخصص الدقيق : <u>{user.tss && user.tss.specialization}</u>
                </p>
                <p>
                  {" "}
                  الدرجة العلمية : <u>{user.tss && user.tss.nowdegree}</u>
                </p>
              </Fragment>
            )}
          </div>
          <div className="all-center">
            <img
              src={`https://portal.zu.edu.ly${image}`}
              className="round-img"
              alt=""
              style={{ width: "150px" }}
            />
            <h6>{name}</h6>
          </div>
        </div>
        {user.resume && user.resume.courses !== "" ? (
          <div className="m-3">
            <h4> :المراكز التي شغلها </h4>
            <p dangerouslySetInnerHTML={{ __html: user.resume.courses }}></p>
          </div>
        ) : null}
      </div>
      <div className="card text-center">
        {user.resume && (
          <a
            href={`https://portal.zu.edu.ly${user.resume.file}`}
            style={{ fontSize: "3rem" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            CV
          </a>
        )}
        {user.researchgate && user.researchgate.length > 0 ? (
          <a
            target="_blank"
            href={user.researchgate}
            className=""
            rel="noopener noreferrer"
          >
            <i className="fab fa-researchgate fa-3x"></i>{" "}
          </a>
        ) : null}

        {user.linkedin && user.linkedin.length > 0 ? (
          <a
            target="_blank"
            href={user.linkedin}
            className=""
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin fa-3x"></i>{" "}
          </a>
        ) : null}

        {user.gscholar && user.gscholar.length > 0 ? (
          <a
            target="_blank"
            href={user.gscholar}
            style={{ fontSize: "3rem" }}
            rel="noopener noreferrer"
          >
            GS{" "}
          </a>
        ) : null}

        {user.email && user.email.length > 0 ? (
          <a
            target="_blank"
            href={`mailto: ${user.email}`}
            rel="noopener noreferrer"
          >
            <i className="fas fa-envelope fa-3x"></i>
          </a>
        ) : null}
      </div>
      <div className="card ">
        <h4 className="text-center"> المواد </h4>

        <small>{user.name}</small>
        <hr />
        {subjects}
      </div>
      <div className="card ">
        <h4 className="text-center">الابحاث المنشورة</h4>
        <small>{user.name}</small>
        <hr />
        {researchs}
      </div>
    </Fragment>
  );
};

export default User;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const UserItem = ({ subject: { _id, subjectname, image, about } }) => {
  return (
    <div className="col s12 " style={{ direction: "rtl" }} key={_id}>
      <div className="card horizontal">
        <div className="card-image">
          <img src={`https://portal.zu.edu.ly${image}`} alt="courses" />
        </div>
        <div className="card-stacked">
          <div className="card-content">
            <h5>{subjectname}</h5>
            <p>{about}</p>
          </div>
          <div className="card-action text-center">
            <Link to={`/subject/${_id}`}>التفاصيل</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

UserItem.propTypes = {
  subject: PropTypes.object.isRequired
};

export default UserItem;

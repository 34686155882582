import React, { useReducer } from "react";
import axios from "axios";
import GithubContext from "./githubContext";
import GithubReducer from "./githubReducer";
import {
  SEARCH_USERS,
  SET_LOADING,
  CLEAR_USERS,
  GET_USER,
  GET_SUBJECT,
  GET_USERS
} from "../types";

const GithubState = props => {
  const initialState = {
    users: [],
    user: {},
    subject: {},
    loading: false
  };

  const [state, dispatch] = useReducer(GithubReducer, initialState);

  // Search Users
  const searchUsers = async text => {
    setLoading();
    try {
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
      };
      const res = await axios.get(
        `https://portal.zu.edu.ly/api/facultymembers?text=${text}`,
        config
      );

      console.log(res);

      dispatch({
        type: SEARCH_USERS,
        payload: res.data
      });
    } catch (err) {
      console.log(err);
    }
  };
  // Get all Users
  const getUsers = async text => {
    setLoading();
    try {
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
      };
      const res = await axios.get(
        `https://portal.zu.edu.ly/api/facultymembers/all`,
        config
      );

      dispatch({
        type: GET_USERS,
        payload: res.data
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Get User
  const getUser = async id => {
    setLoading();

    const res = await axios.get(
      `https://portal.zu.edu.ly/api/facultymembers/${id}`
    );

    dispatch({
      type: GET_USER,
      payload: res.data
    });
  };

  // Get User
  const getSubject = async id => {
    setLoading();

    const res = await axios.get(
      `https://portal.zu.edu.ly/api/facultymembers/subject/${id}`
    );

    dispatch({
      type: GET_SUBJECT,
      payload: res.data
    });
  };

  // Clear Users
  const clearUsers = () => dispatch({ type: CLEAR_USERS });

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <GithubContext.Provider
      value={{
        users: state.users,
        user: state.user,
        subject: state.subject,
        loading: state.loading,
        getUsers,
        searchUsers,
        clearUsers,
        getUser,
        getSubject
      }}
    >
      {props.children}
    </GithubContext.Provider>
  );
};

export default GithubState;

import React, { useState, useContext, useEffect } from "react";
import GithubContext from "../../context/github/githubContext";
import AlertContext from "../../context/alert/alertContext";

const Search = () => {
  useEffect(() => {
    githubContext.getUsers();
  }, []);
  const githubContext = useContext(GithubContext);
  const alertContext = useContext(AlertContext);

  const [text, setText] = useState("");

  const onSubmit = e => {
    e.preventDefault();
    if (text === "") {
      alertContext.setAlert("Please enter something", "light");
    } else {
      githubContext.searchUsers(text);
      setText("");
    }
  };

  const onChange = e => setText(e.target.value);

  return (
    <div>
      <a href="http://zu.edu.ly/university" className="btn btn-light">
        صفحة الجامعة
      </a>
      <form onSubmit={onSubmit} className="form">
        <input
          type="text"
          name="text"
          className="text-right"
          placeholder=" ...اسم عضو هيئة التدريس"
          value={text}
          onChange={onChange}
        />
        <input type="submit" value="بحث" className="btn btn-dark btn-block" />
      </form>
      {githubContext.users.length > 0 && (
        <button
          className="btn btn-light btn-block"
          onClick={githubContext.clearUsers}
        >
          مسح
        </button>
      )}
    </div>
  );
};

export default Search;

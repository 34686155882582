import React, { useContext } from "react";
import SubjectItem from "./SubjectItem";
import Spinner from "../layout/Spinner";
import SubjectContext from "../../context/Subjects/subjectContext";

const Subject = () => {
  const githubContext = useContext(SubjectContext);

  const { loading, subjects } = githubContext;

  if (loading) {
    return <Spinner />;
  } else {
    return (
      <div>
        {subjects.map(subject => (
          <SubjectItem key={subject._id} subject={subject} />
        ))}
      </div>
    );
  }
};

export default Subject;

import React, { Fragment, useContext, useEffect, useState } from "react";

import GithubContext from "../../context/github/githubContext";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import M from "materialize-css/dist/js/materialize.min.js";

const Subject = ({ match }) => {
  const githubContext = useContext(GithubContext);
  const { getSubject, subject } = githubContext;

  const [videourl, setvideo] = useState({ uri: "", videoname: "" });
  useEffect(() => {
    getSubject(match.params.id);
    // eslint-disable-next-line
    M.AutoInit();
  }, []);

  const videourlchange = (urlv, name) => {
    console.log("Url : " + urlv);
    setvideo({ uri: urlv, videoname: name });
    console.log(videourl);
  };

  const { subjectname, subjectteacher, about, image } = subject;
  const files = [];

  for (var ks in subject.files) {
    files.push(
      <li className="collection-item avatar " key={ks}>
        {subject.files[ks].category === "video" ? (
          <Fragment>
            <a
              onClick={videourlchange.bind(
                null,
                subject.files[ks].link,
                subject.files[ks].filename
              )}
              className="  modal-trigger"
              href="#modal1"
            >
              <i className="material-icons circle red ">play_arrow</i>
            </a>

            <span className="title">{subject.files[ks].filename}</span>
            <p>{subject.files[ks].discription}</p>
          </Fragment>
        ) : (
          <Fragment>
            <a
              href={`https://portal.zu.edu.ly${subject.files[ks].file}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {"  "}
              <i className="material-icons circle">insert_drive_file</i>
            </a>

            <span className="title">{subject.files[ks].filename}</span>
            <p>{subject.files[ks].discription}</p>
          </Fragment>
        )}
      </li>
    );
  }

  return (
    <Fragment>
      <Link to="/" className="btn btn-light">
        الرجوع للبحث
      </Link>

      <Fragment>
        <div className="col s12 " style={{ direction: "rtl" }}>
          <div className="card horizontal">
            <div className="card-image">
              <img src={`https://portal.zu.edu.ly${image}`} alt="subjects" />
            </div>
            <div className="card-stacked">
              <div className="card-content">
                <h5>
                  {" "}
                  المادة :<u> {subjectname}</u>
                </h5>
                الاستاذ : <u>{subjectteacher}</u>
                <p>
                  عن المادة : <u>{about}</u>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card ">
          <h4 className="text-center my-2">
            {" "}
            <u>المادة العلمية</u>
          </h4>

          <ul className="collection" style={{ direction: "rtl" }}>
            {files}
          </ul>

          <div id="modal1" className="modal" style={{ direction: "rtl" }}>
            <div className="modal-content">
              <h4 className="text-right">{videourl.videoname}</h4>
              <ReactPlayer url={videourl.uri} controls={true} />
            </div>

            <div className="modal-footer">
              <a
                href="#!"
                className="modal-close waves-effect waves-green btn-flat"
              >
                اغلاق
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};

export default Subject;
